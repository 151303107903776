import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ExternalLoginComponent } from './pages/external-login/external-login.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ResetPasswordRequestComponent } from './pages/reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { UserRegistrationComponent } from './pages/user-registration/user-registration.component';

const routes: Routes = [
  // Login page from external websites
  { path: 'auth/:key', component: ExternalLoginComponent },
  // GD Account internal routing
  { path: 'login', component: LoginComponent },
  { path: 'user-registration', component: UserRegistrationComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-password-request', component: ResetPasswordRequestComponent},
  // Protected routes
  { path: 'dashboard', component: DashboardComponent },
  { path: 'user-profile', component: ProfileComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  // Otherwise redirect to login
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
