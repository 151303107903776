<!-- Page Content -->
<div class="row justify-content-center h-100">
  <div class="col-sm-12 col-md-6 col-lg-5 align-self-center form-group">

    <!-- Logo -->
    <img src="./../../../assets/img/genio-diligence-logo-colored.png" alt="Genio Diligence" class="logo">

    <!-- Card -->
    <div class="card shadow rounded">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Dashboard</h3>
        <p class="card-text text-center">Ciao <i class="text-secondary">{{ username }}</i> !</p>
        <p class="card-text text-center mb-5">Questa &egrave; il tuo pannello di controllo. Puoi fare una delle seguenti
          azioni:</p>
        <div class="list-group">
          <a href="/user-profile" title="Accedi alla pagina delle informazioni del tuo profilo"
            class="list-group-item list-group-item-action text-primary"><i class="fas fa-address-card mr-3"></i>
            Visualizza il tuo profilo</a>
          <a href="/change-password" title="Avvia la procedura per cambiare la tua password"
            class="list-group-item list-group-item-action text-primary"><i class="fas fa-key mr-3"></i> Modifica la tua
            password</a>
          <a href="/logout" title="Chiudi la tua sessione"
            class="list-group-item list-group-item-action text-primary"><i class="fas fa-sign-out-alt mr-3"></i>
            Logout</a>
        </div>
      </div>
    </div>

  </div>
</div>
