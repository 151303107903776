<!-- Loading Animation -->
<loading-animation [showLoading]="loading"></loading-animation>

<!-- Page Content -->
<div class="row justify-content-center h-100">
  <div class="col-sm-12 col-md-6 col-lg-5 align-self-center form-group">

    <!-- Logo -->
    <img src="./../../../assets/img/genio-diligence-logo-colored.png" alt="Genio Diligence" class="logo">

    <!-- Card -->
    <div class="card shadow rounded" *ngIf="showForm">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Login</h3>
        <p class="card-text text-center mb-5">Inserisci username e password per effettuare il login.</p>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <!-- Username -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="username" class="text-muted">Username:</label>
              <input type="text" name="username" id="username" class="form-control" formControlName="username"
                placeholder="Scrivi il tuo username qui" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Inserisci il tuo username</div>
              </div>
            </div>
          </div>

          <!-- Password -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="password" class="text-muted">Password:</label>
              <div class="input-group">
                <input [type]="fieldTextType ? 'text' : 'password'" name="password" id="password" class="form-control"
                  formControlName="password" placeholder="Scrivi la tua password qui"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                <div class="input-group-append">
                  <span class="input-group-text toggle-password-btn" (click)="toggleFieldTextType()"
                    data-toggle="tooltip" data-placement="top"
                    [title]="fieldTextType ? 'Clicca per nascondere la password' : 'Clicca per mostrare la password'">
                    <!-- icon show password -->
                    <span *ngIf="fieldTextType">
                      <i class="far fa-eye"></i>
                    </span>
                    <!-- icon hide password -->
                    <span *ngIf="!fieldTextType">
                      <i class="far fa-eye-slash"></i>
                    </span>
                  </span>
                </div>
              </div>
              <!-- Error messages -->
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Inserisci la tua password</div>
              </div>
            </div>
          </div>

          <!-- Login button -->
          <div class="row justify-content-center mt-4">
            <div class="col-12 form-group">
              <button type="submit" class="btn btn-primary btn-block">
                Login
              </button>
              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </div>
          </div>

        </form>

        <!-- Links to other pages -->
        <p class="mt-5 text-center">
          <a href="/reset-password-request"
            alt="Clicca per richiedere la procedura di recupero della password" data-toggle="tooltip"
            data-placement="top" title="Clicca per richiedere la procedura di recupero della password">
            Recupera la tua password
          </a>
        </p>

        <p class="text-center">
          <a href="/user-registration"
            alt="Registrati per " data-toggle="tooltip"
            data-placement="top" title="Clicca per richiedere la procedura di recupero della password">
            Registrati
          </a>
        </p>
        
      </div>
    </div>

    <!-- Error message -->
    <div class="card shadow rounder" *ngIf="!showForm">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Login</h3>
        <div class="alert alert-danger" role="alert">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</div>
