import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
// Pages
import { LoginComponent } from './pages/login/login.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetPasswordRequestComponent } from './pages/reset-password-request/reset-password-request.component';
import { UserRegistrationComponent } from './pages/user-registration/user-registration.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ExternalLoginComponent } from './pages/external-login/external-login.component';
// Shared Components
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
// Services
import { LoginService } from '@app/_services';
// Recaptcha Module
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    LoadingAnimationComponent,
    ResetPasswordRequestComponent,
    UserRegistrationComponent,
    DashboardComponent,
    ProfileComponent,
    ExternalLoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    DatePipe,
    LoginService,
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LehQt8ZAAAAAGDGVQrVPKSmEkjUMWZnU-fCUfpR' } as RecaptchaSettings },
    { provide: RECAPTCHA_LANGUAGE, useValue: 'it'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
