import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import custom validator to validate that password and confirm password fields match
import { MustMatch } from '@app/_helpers/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetFormGroup: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  returnUrl: string = "";

  // Show or Hide password
  fieldNewPaswTextType: boolean = false;
  fieldConfirmPasswTextType: boolean = false;

  // Show new password form
  resetForm: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.resetFormGroup = this.formBuilder.group({
      newpassword: ['', [
        Validators.required, 
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9$@$!%*?&].{1,}'),
        Validators.minLength(8),
        Validators.maxLength(16)
      ]],
      confirmpassword: ['', Validators.required]
    }, {
      validator: MustMatch('newpassword', 'confirmpassword')
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get s() { return this.resetFormGroup.controls; }

  // Action for reset password form module
  onReset() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetFormGroup.invalid) {
      return;
    }

    // Set a fake loading animation then show message sent
    this.loading = true;
    setTimeout (() => {
      this.loading = false;
    }, 4000);

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.resetFormGroup.value, null, 4));

  }

  // Switching field type methods

  toggleFieldNewPasswTextType() {
    this.fieldNewPaswTextType = !this.fieldNewPaswTextType;
  }

  toggleFieldConfirmPasswTextType() {
    this.fieldConfirmPasswTextType = !this.fieldConfirmPasswTextType;
  }

}
