import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

// HTTP headers
const headers = new HttpHeaders().set("Content-Type", "application/json");

@Injectable({ providedIn: 'root' })
export class LoginService {  

  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) {
    console.log("Login service started!")
  }

  // Token Validation
  validateToken(token:string): Observable<any> {
    let url = this.baseUrl + '/idp/authrequest/validate/' + token;
    return this.httpClient.get(url);
  };

  // Login check
  loginCheck(username:string, password:string, key:string): Observable<any> {
    let url = this.baseUrl + '/idp/authrequest/check';
    return this.httpClient.post(url, {username, password, key}, {headers});
  }

  // Finalize login and redirect
  loginFinalize(key:string) {
    let url = this.baseUrl + '/idp/authrequest/finalize/' + key;
    window.location.href = url;
  }

}
