<!-- Loading Animation -->
<loading-animation [showLoading]="loading"></loading-animation>

<!-- Page Content -->
<div class="row justify-content-center h-100">
  <div class="col-sm-12 col-md-8 col-lg-5 align-self-center form-group">

    <!-- Logo -->
    <img src="./../../../assets/img/genio-diligence-logo-colored.png" alt="Genio Diligence" class="logo">

    <!-- First Step Card -->
    <div class="card shadow rounded mb-3" *ngIf="firstStep">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Registrazione</h3>
        <p class="card-text text-center mb-5">Un account. Tutti i servizi Genio Diligence.</p>
        <h5 class="text-center mb-5">Possiedi una partita IVA?</h5>
        <button type="button" class="btn btn-primary btn-lg btn-block" (click)="hasPIva()">S&igrave;</button>
        <button type="button" class="btn btn-primary btn-lg btn-block" (click)="hasNotPiva()">No</button>
      </div>
    </div>

    <!-------------------------------------------------------------------------------------------------->

    <!-- P IVA Registration Card -->
    <div class="card shadow rounded mb-3" *ngIf="!firstStep && isPiva">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Registrazione con P.IVA</h3>
        <p class="card-text text-center mb-5">Compila i campi per completare la registrazione:</p>

        <!-- P.IVA registration form start here -->
        <form [formGroup]="pivaForm" class="mb-5" (ngSubmit)="onSubmitPIVA()">

          <!-- User Type -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="companyType" class="text-muted">Tiologia:</label>
              <select class="form-control" id="companyType" formControlName="companyType">
                <option selected disabled value="">Seleziona...</option>
                <option *ngFor="let item of companyTypes" [ngValue]="item.type">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- Ragione Sociale -->
          <div class="row" *ngIf="pivaForm.value.companyType">
            <div class="col-12 form-group">
              <label for="ragsociale" class="text-muted">Ragione Sociale</label>
              <input type="text" name="ragsociale" id="rag-sociale" class="form-control" formControlName="ragsociale"
                placeholder="Scrivi la Ragione Sociale qui"
                [ngClass]="{ 'is-invalid': submitted && f.ragsociale.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.ragsociale.errors" class="invalid-feedback">
                <div *ngIf="f.ragsociale.errors.required">Inserisci la Ragione Sociale</div>
              </div>
            </div>
          </div>

          <!-- Indirizzo -->
          <div class="row" *ngIf="pivaForm.value.companyType">
            <div class="col-12 form-group">
              <label for="indirizzo" class="text-muted">Indirizzo</label>
              <input type="text" name="indirizzo" id="indirizzo" class="form-control" formControlName="indirizzo"
                placeholder="Scrivi l'indirizzo qui" [ngClass]="{ 'is-invalid': submitted && f.indirizzo.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.indirizzo.errors" class="invalid-feedback">
                <div *ngIf="f.indirizzo.errors.required">Inserisci l'indirizzo</div>
              </div>
            </div>
          </div>

          <!-- Città -->
          <div class="row" *ngIf="pivaForm.value.companyType">
            <div class="col-12 form-group">
              <label for="citta" class="text-muted">Citt&agrave;</label>
              <input type="text" name="citta" id="citta" class="form-control" formControlName="citta"
                placeholder="Scrivi la citt&agrave; qui" [ngClass]="{ 'is-invalid': submitted && f.citta.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.citta.errors" class="invalid-feedback">
                <div *ngIf="f.citta.errors.required">Inserisci la citt&agrave;</div>
              </div>
            </div>
          </div>

          <!-- Provincia e CAP -->
          <div class="row" *ngIf="pivaForm.value.companyType">
            <div class="col-sm-12 col-md-8 form-group">
              <label for="provincia" class="text-muted">Provincia</label>
              <input type="text" name="provincia" id="provincia" class="form-control" formControlName="provincia"
                placeholder="Scrivi la Provincia qui" [ngClass]="{ 'is-invalid': submitted && f.provincia.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.provincia.errors" class="invalid-feedback">
                <div *ngIf="f.provincia.errors.required">Inserisci la provincia</div>
              </div>
            </div>

            <div class="col-sm-12 col-md-4 form-group">
              <label for="cap" class="text-muted">CAP</label>
              <input type="number" name="cap" id="cap" class="form-control" formControlName="cap" placeholder="CAP"
                [ngClass]="{ 'is-invalid': submitted && f.cap.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.cap.errors" class="invalid-feedback">
                <div *ngIf="f.cap.errors.required">Inserisci il CAP</div>
              </div>
            </div>
          </div>

          <!-- Codice NREA CCIAA e SDI -->
          <div class="row" *ngIf="pivaForm.value.companyType && pivaForm.value.companyType != 'lib-professionista'">

            <div class="col-sm-12 col-md-6 form-group">
              <label for="nreacciaa" class="text-muted">Codice NREA CCIAA</label>
              <input type="text" name="nreacciaa" id="nreacciaa" class="form-control" formControlName="nreacciaa"
                placeholder="Codice NREA CCIAA" [ngClass]="{ 'is-invalid': submitted && f.nreacciaa.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.nreacciaa.errors" class="invalid-feedback">
                <div *ngIf="f.nreacciaa.errors.required">Inserisci il codice NREA CCIAA</div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 form-group">
              <label for="sdi" class="text-muted">Codice SDI</label>
              <input type="text" name="sdi" id="sdi" class="form-control" formControlName="sdi" placeholder="Codice SDI"
                [ngClass]="{ 'is-invalid': submitted && f.sdi.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.sdi.errors" class="invalid-feedback">
                <div *ngIf="f.sdi.errors.required">Inserisci il codice SDI</div>
              </div>
            </div>

          </div>

          <!-- Codice PIVA -->
          <div class="row" *ngIf="pivaForm.value.companyType">
            <div class="col-12 form-group">
              <label for="piva" class="text-muted">P. IVA</label>
              <input type="text" name="piva" id="piva" class="form-control" formControlName="piva"
                placeholder="Scrivi la P. IVA qui" [ngClass]="{ 'is-invalid': submitted && f.piva.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.citta.errors" class="invalid-feedback">
                <div *ngIf="f.piva.errors.required">Inserisci la P. IVA</div>
              </div>
            </div>
          </div>

          <!-- Codice Fiscale -->
          <div class="row" *ngIf="pivaForm.value.companyType">
            <div class="col-12 form-group">
              <label for="companycf" class="text-muted">Codice Fiscale</label>
              <input type="text" name="companycf" id="companycf" class="form-control" formControlName="companycf"
                placeholder="Scrivi il Codice Fiscale qui" [ngClass]="{ 'is-invalid': submitted && f.companycf.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.companycf.errors" class="invalid-feedback">
                <div *ngIf="f.companycf.errors.required">Inserisci il Codice Fiscale</div>
              </div>
            </div>
          </div>

          <!-- email -->
          <div class="row" *ngIf="pivaForm.value.companyType">
            <div class="col-12 form-group">
              <label for="companyemail" class="text-muted">Email</label>
              <input type="email" name="companyemail" id="companyemail" class="form-control" formControlName="companyemail"
                placeholder="Scrivi l'email qui" [ngClass]="{ 'is-invalid': submitted && f.companyemail.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.companyemail.errors" class="invalid-feedback">
                <div *ngIf="f.companyemail.errors.required">Inserisci l'email</div>
                <div *ngIf="f.companyemail.errors.email">Devi inserire una email valida</div>
              </div>
            </div>
          </div>

          <!-- PEC -->
          <div class="row" *ngIf="pivaForm.value.companyType">
            <div class="col-12 form-group">
              <label for="companypec" class="text-muted">PEC</label>
              <input type="email" name="companypec" id="companypec" class="form-control" formControlName="companypec" placeholder="Scrivi la PEC qui"
                [ngClass]="{ 'is-invalid': submitted && f.companypec.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.companypec.errors" class="invalid-feedback">
                <div *ngIf="f.companypec.errors.email">Devi inserire una email valida</div>
              </div>
            </div>
          </div>

          <p class="divider line one-line"
            *ngIf="pivaForm.value.companyType && pivaForm.value.companyType != 'lib-professionista'">Legale
            Rappresentante</p>

          <!-- Nome e Cognome rappresentante legale -->
          <div class="row" *ngIf="pivaForm.value.companyType && pivaForm.value.companyType != 'lib-professionista'">

            <div class="col-sm-12 col-md-6 form-group">
              <label for="rlname" class="text-muted">Nome</label>
              <input type="text" name="rlname" id="rlname" class="form-control" formControlName="rlname"
                placeholder="Scrivi il nome qui" [ngClass]="{ 'is-invalid': submitted && f.rlname.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.rlname.errors" class="invalid-feedback">
                <div *ngIf="f.rlname.errors.required">Inserisci il nome</div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 form-group">
              <label for="rlsurname" class="text-muted">Cognome</label>
              <input type="text" name="rlsurname" id="rlsurname" class="form-control" formControlName="rlsurname"
                placeholder="Scrivi il cognome qui" [ngClass]="{ 'is-invalid': submitted && f.rlsurname.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.rlsurname.errors" class="invalid-feedback">
                <div *ngIf="f.rlsurname.errors.required">Inserisci il cognome</div>
              </div>
            </div>

          </div>

          <!-- Codice Fiscale rappresentante legale -->
          <div class="row" *ngIf="pivaForm.value.companyType && pivaForm.value.companyType != 'lib-professionista'">
            <div class="col-12 form-group">
              <label for="rlcf" class="text-muted">Codice Fiscale</label>
              <input type="text" name="rlcf" id="rlcf" class="form-control" formControlName="rlcf"
                placeholder="Scrivi il Codice Fiscale qui" [ngClass]="{ 'is-invalid': submitted && f.rlcf.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.rlcf.errors" class="invalid-feedback">
                <div *ngIf="f.rlcf.errors.required">Inserisci il Codice Fiscale</div>
              </div>
            </div>
          </div>

          <!-- Register button -->
          <div class="row justify-content-center mt-4" *ngIf="pivaForm.value.companyType">
            <div class="col-12 form-group">
              <button type="submit" class="btn btn-primary btn-block" id="companyRegisterBtn">
                Registrati
              </button>
              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </div>
          </div>

        </form>

        <button class="btn btn-link btn-block" type="button" role="button" (click)="hasNotPiva()"
          title="Registrati come utente privato">Non sei possessore di partita IVA?</button>
      </div>
    </div>

    <!-------------------------------------------------------------------------------------------------->

    <!-- Private Registration Card -->
    <div class="card shadow rounded mb-3" *ngIf="!firstStep && !isPiva">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Registrazione Utenti Privati</h3>
        <p class="card-text text-center mb-5">Compila i campi per completare la registrazione:</p>

        <!-- Private user registration form start here -->
        <form [formGroup]="notPivaForm" class="mb-5" (ngSubmit)="onSubmitNotPIVA()">

          <!-- Nome e Cognome -->
          <div class="row">

            <div class="col-sm-12 col-md-6 form-group">
              <label for="name" class="text-muted">Nome</label>
              <input type="text" name="name" id="name" class="form-control" formControlName="name"
                placeholder="Scrivi il nome qui" [ngClass]="{ 'is-invalid': submitted && p.name.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && p.name.errors" class="invalid-feedback">
                <div *ngIf="p.name.errors.required">Inserisci il nome</div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 form-group">
              <label for="surname" class="text-muted">Cognome</label>
              <input type="text" name="surname" id="surname" class="form-control" formControlName="surname"
                placeholder="Scrivi il cognome qui" [ngClass]="{ 'is-invalid': submitted && p.surname.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && p.surname.errors" class="invalid-feedback">
                <div *ngIf="p.surname.errors.required">Inserisci il cognome</div>
              </div>
            </div>

          </div>

          <!-- Indirizzo -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="address" class="text-muted">Indirizzo</label>
              <input type="text" name="address" id="address" class="form-control" formControlName="address"
                placeholder="Scrivi l'indirizzo qui" [ngClass]="{ 'is-invalid': submitted && p.address.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && p.address.errors" class="invalid-feedback">
                <div *ngIf="p.address.errors.required">Inserisci l'indirizzo</div>
              </div>
            </div>
          </div>

          <!-- Città -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="city" class="text-muted">Citt&agrave;</label>
              <input type="text" name="city" id="city" class="form-control" formControlName="city"
                placeholder="Scrivi la citt&agrave; qui" [ngClass]="{ 'is-invalid': submitted && p.city.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && p.city.errors" class="invalid-feedback">
                <div *ngIf="p.city.errors.required">Inserisci la citt&agrave;</div>
              </div>
            </div>
          </div>

          <!-- Provincia e CAP -->
          <div class="row">
            <div class="col-sm-12 col-md-8 form-group">
              <label for="province" class="text-muted">Provincia</label>
              <input type="text" name="province" id="province" class="form-control" formControlName="province"
                placeholder="Scrivi la Provincia qui" [ngClass]="{ 'is-invalid': submitted && p.province.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && p.province.errors" class="invalid-feedback">
                <div *ngIf="p.province.errors.required">Inserisci la provincia</div>
              </div>
            </div>

            <div class="col-sm-12 col-md-4 form-group">
              <label for="cap" class="text-muted">CAP</label>
              <input type="number" name="cap" id="cap" class="form-control" formControlName="cap" placeholder="CAP"
                [ngClass]="{ 'is-invalid': submitted && p.cap.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && p.cap.errors" class="invalid-feedback">
                <div *ngIf="p.cap.errors.required">Inserisci il CAP</div>
              </div>
            </div>
          </div>

          <!-- Codice Fiscale -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="cf" class="text-muted">Codice Fiscale</label>
              <input type="text" name="cf" id="cf" class="form-control" formControlName="cf"
                placeholder="Scrivi il Codice Fiscale qui" [ngClass]="{ 'is-invalid': submitted && p.cf.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && p.cf.errors" class="invalid-feedback">
                <div *ngIf="p.cf.errors.required">Inserisci il Codice Fiscale</div>
              </div>
            </div>
          </div>

           <!-- email -->
           <div class="row">
            <div class="col-12 form-group">
              <label for="email" class="text-muted">Email</label>
              <input type="email" name="email" id="email" class="form-control" formControlName="email"
                placeholder="Scrivi l'email qui" [ngClass]="{ 'is-invalid': submitted && p.email.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && p.email.errors" class="invalid-feedback">
                <div *ngIf="p.email.errors.required">Inserisci l'email</div>
                <div *ngIf="p.email.errors.email">Devi inserire una email valida</div>
              </div>
            </div>
          </div>

          <!-- PEC -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="pec" class="text-muted">PEC</label>
              <input type="email" name="pec" id="pec" class="form-control" formControlName="pec" placeholder="Scrivi la PEC qui"
                [ngClass]="{ 'is-invalid': submitted && p.pec.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && p.pec.errors" class="invalid-feedback">
                <div *ngIf="p.pec.errors.email">Devi inserire una email valida</div>
              </div>
            </div>
          </div>

          <!-- Register button -->
          <div class="row justify-content-center mt-4">
            <div class="col-12 form-group">
              <button type="submit" class="btn btn-primary btn-block" id="privateRegistrationBtn">
                Registrati
              </button>
              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </div>
          </div>

        </form>

        <button class="btn btn-link btn-block" type="button" role="button" (click)="hasPIva()"
          title="Registrati come utente con Partita IVA">Sei possessore di partita IVA?</button>

      </div>
    </div>

    <p class="text-center">Hai gi&agrave; un'account? <a href="/" title="Effettua il login">Effettua il login</a></p>

  </div>
</div>
