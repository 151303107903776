import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss']
})
export class ResetPasswordRequestComponent implements OnInit {

  form: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  returnUrl: string = "";

  // Show or Hide password
  fieldTextType: boolean = false;

  // Show response card
  emailSentResponse: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    // Load form control validation
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      recaptchaReactive: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  // Action for request email link form module
  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.form.value, null, 4));

    // Set a fake loading animation then show message sent
    this.loading = true;
    setTimeout (() => {
      this.loading = false;
      this.emailSentResponse = true;
    }, 4000);
  }

  // Recaptcha resolve
  resolved(captchaResponse: string) {
    console.log('Resolved captcha with response: ' + captchaResponse);
  }

}
