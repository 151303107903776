import { Component, OnChanges, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {

  loading: boolean = false;
  firstStep: boolean = true;
  isPiva: boolean = true;
  submitted: boolean = false;
  error: string = "";
  companyTypes: any = [];

  pivaForm: FormGroup;
  notPivaForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    
  }

  companyTypeList() {
    // Company Type select list
    this.companyTypes = [
      { type: 'ditta-individuale', name: 'Ditta Individuale' },
      { type: 'soc-persone', name: 'Società di Persone' },
      { type: 'soc-capitale', name: 'Società di Capitale' },
      { type: 'lib-professionista', name: 'Libero Professionista' }
    ];
  }

  // Validation controls for piva form
  pivaFormValidation() {
    this.pivaForm = this.formBuilder.group({
      companyType: [''],
      ragsociale: ['', Validators.required],
      indirizzo: ['', Validators.required],
      citta: ['', Validators.required],
      provincia: ['', Validators.required],
      cap: ['', Validators.required],
      piva: ['', Validators.required],
      companycf: ['', Validators.required],
      companyemail: ['', [
        Validators.required, 
        Validators.email
      ]],
      companypec: ['', Validators.email],
      // conditional validations (the use of null is a sort of workaround)
      nreacciaa: [null],
      sdi: [null],
      rlname: [null],
      rlsurname: [null],
      rlcf: [null]
    });
  }

  // Validation controls for private user form
  notPivaFormValidation() {
    this.notPivaForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      cap: ['', Validators.required],
      cf: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      pec: ['', Validators.email]
    });
  }

  // Conditional validation based on selected company type
  conditionalValidation() {
    const nreacciaaControl = this.pivaForm.get('nreacciaa');
    const sdiControl = this.pivaForm.get('sdi');
    const rlnameControl = this.pivaForm.get('rlname');
    const rlsurnameControl = this.pivaForm.get('rlsurname');
    const rlcfControl = this.pivaForm.get('rlcf');

    this.pivaForm.get('companyType').valueChanges.subscribe(companyType => {
      if (companyType != 'lib-professionista') {
        nreacciaaControl.setValidators([Validators.required]);
        sdiControl.setValidators([Validators.required]);
        rlnameControl.setValidators([Validators.required]);
        rlsurnameControl.setValidators([Validators.required]);
        rlcfControl.setValidators([Validators.required]);
      }
    })
  }

  // Convenience getter for easy access to form fields
  get f() { return this.pivaForm.controls; }
  get p() { return this.notPivaForm.controls; }

  // Form PIVA submit action
  onSubmitPIVA() {
    this.submitted = true;

    if (this.pivaForm.invalid) {
      return;
    }

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.pivaForm.value, null, 4));
  }

  // Form Private User submit action
  onSubmitNotPIVA() {
    this.submitted = true;

    if (this.notPivaForm.invalid) {
      return;
    }

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.notPivaForm.value, null, 4));
  }

  // Show form card for companies
  hasPIva() {
    this.firstStep = false;
    this.isPiva = true;
    // Load company type list
    this.companyTypeList();
    // Load form default validation controls
    this.pivaFormValidation();
    // Load conditional validations based on company type selection
    this.conditionalValidation();
  }

  // Show form card for private users
  hasNotPiva() {
    this.firstStep = false;
    this.isPiva = false;
    // Load form validators
    this.notPivaFormValidation();
  }

}
