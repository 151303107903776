<!-- Loading Animation -->
<loading-animation [showLoading]="loading"></loading-animation>

<!-- Page Content -->
<div class="row justify-content-center h-100">
  <div class="col-sm-12 col-md-6 col-lg-5 align-self-center form-group">
    <!-- Logo -->
    <img src="./../../../assets/img/genio-diligence-logo-colored.png" alt="Genio Diligence" class="logo">

    <!-- Card change password request -->
    <div class="card shadow rounded">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Cambio Password</h3>
        <p class="card-text text-left">Per mantenere la sicurezza, assicurati che la tua nuova password contenga:</p>
        <ul>
          <li>almeno 8 caratteri</li>
          <li>massimo 16 caratteri</li>
          <li>almeno 1 lettera maiuscola</li>
          <li>almeno 1 lettera minuscola</li>
          <li>almeno 1 numero</li>
          <li>almeno 1 carattere speciale ($@$!%*?&)</li>
        </ul>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <!-- Old Password -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="password" class="text-muted">Vecchia Password:</label>
              <div class="input-group">
                <input [type]="fieldOldPasswType ? 'text' : 'password'" name="oldpassword" id="oldpassword"
                  class="form-control" formControlName="oldpassword" placeholder="Scrivi la tua vecchia password qui"
                  [ngClass]="{ 'is-invalid': submitted && f.oldpassword.errors }">
                <div class="input-group-append">
                  <span class="input-group-text toggle-password-btn" (click)="toggleFieldOldPasswType()"
                    data-toggle="tooltip" data-placement="top"
                    [title]="fieldOldPasswType ? 'Clicca per nascondere la password' : 'Clicca per mostrare la password'">
                    <!-- icon show password -->
                    <span *ngIf="fieldOldPasswType">
                      <i class="far fa-eye"></i>
                    </span>
                    <!-- icon hide password -->
                    <span *ngIf="!fieldOldPasswType">
                      <i class="far fa-eye-slash"></i>
                    </span>
                  </span>
                </div>
              </div>
              <!-- Error messages -->
              <div *ngIf="submitted && f.oldpassword.errors" class="invalid-feedback">
                <div *ngIf="f.oldpassword.errors.required">La vecchia password &egrave; obbligatoria</div>
                <!-- TODO: check correct old password? -->
              </div>
            </div>
          </div>

          <!-- New Password -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="password" class="text-muted">Nuova Password:</label>
              <div class="input-group">
                <input [type]="fieldNewPasswTextType ? 'text' : 'password'" name="newpassword" id="newpassword"
                  class="form-control" formControlName="newpassword" placeholder="Scrivi la tua nuova password qui"
                  [ngClass]="{ 'is-invalid': submitted && f.newpassword.errors }">
                <div class="input-group-append">
                  <span class="input-group-text toggle-password-btn" (click)="toggleFieldNewPasswTextType()"
                    data-toggle="tooltip" data-placement="top"
                    [title]="fieldNewPasswTextType ? 'Clicca per nascondere la password' : 'Clicca per mostrare la password'">
                    <!-- icon show password -->
                    <span *ngIf="fieldNewPasswTextType">
                      <i class="far fa-eye"></i>
                    </span>
                    <!-- icon hide password -->
                    <span *ngIf="!fieldNewPasswTextType">
                      <i class="far fa-eye-slash"></i>
                    </span>
                  </span>
                </div>
              </div>
              <!-- Error messages -->
              <div *ngIf="submitted && f.newpassword.errors" class="invalid-feedback">
                <div *ngIf="f.newpassword.errors.required">La nuova password &egrave; obbligatoria</div>
                <div *ngIf="f.newpassword.errors.minlength">La nuova password deve contenere almeno 8 caratteri</div>
                <div *ngIf="f.newpassword.errors.maxlength">La nuova password deve essere al massimo di 16 caratteri</div>
                <div *ngIf="f.newpassword.errors.pattern">La nuova password deve contenere almeno 1 minuscola, 1 maiuscola, 1
                  numero, 1 carattere speciale</div>
                <div *ngIf="f.newpassword.errors.mustMismatch">La nuova password non pu&ograve; coincidere con quella vecchia</div>
              </div>
            </div>
          </div>

          <!-- Confirm Password -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="password" class="text-muted">Conferma Nuova Password:</label>
              <div class="input-group">
                <input [type]="fieldConfirmPasswTextType ? 'text' : 'password'" name="confirmpassword"
                  id="confirmpassword" class="form-control" formControlName="confirmpassword"
                  placeholder="Conferma la tua nuova password qui"
                  [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }">
                <div class="input-group-append">
                  <span class="input-group-text toggle-password-btn" (click)="toggleFieldConfirmPasswTextType()"
                    data-toggle="tooltip" data-placement="top"
                    [title]="fieldConfirmPasswTextType ? 'Clicca per nascondere la password' : 'Clicca per mostrare la password'">
                    <!-- icon show password -->
                    <span *ngIf="fieldConfirmPasswTextType">
                      <i class="far fa-eye"></i>
                    </span>
                    <!-- icon hide password -->
                    <span *ngIf="!fieldConfirmPasswTextType">
                      <i class="far fa-eye-slash"></i>
                    </span>
                  </span>
                </div>
              </div>
              <!-- Error messages -->
              <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmpassword.errors.required">La password di conferma &egrave; obbligatoria</div>
                <div *ngIf="f.confirmpassword.errors.mustMatch">La password di conferma deve essere uguale alla nuova
                  password</div>
              </div>
            </div>
          </div>

          <!-- Change password button -->
          <div class="row justify-content-center mt-4">
            <div class="col-12 form-group">
              <button type="submit" class="btn btn-primary btn-block">
                Cambia la password
              </button>
            </div>
          </div>

        </form>

      </div>
    </div>

  </div>
</div>