<!-- Page Loading Animation -->
<div class="loading-animation" *ngIf="showLoading">
  <div class="loading-animation-content">
    <div class="loading-animation-text mb-4">
      <img src="./../../../assets/img/genio-diligence-logo.png" alt="Genio Diligence" height="100">
    </div>
    <div class="fa-3x">
      <i class="fas fa-sync fa-spin"></i>
    </div>
    <div class="loading-animation-text">
      Caricamento
    </div>
  </div>
</div>