import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '@app/_services';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss']
})
export class ExternalLoginComponent implements OnInit {

  loading: boolean = true;
  // Form variables
  form: FormGroup;
  submitted: boolean = false;
  showForm: boolean = true;
  fieldTextType: boolean = false;
  error: string = "";
  // Token variable
  key: string = "";
  // Date variables
  now: number = Date.now();
  datePipeString : string;
  // HTTP requests variables 
  validationobj: any = [];
  loginResponse: any = [];
  loginFinalize: any = [];

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private datePipe: DatePipe) { 
      // Set now format date as expire date of the token
      this.datePipeString = this.datePipe.transform(Date.now(),'yyyy-MM-dd H:mm:ss');
    }

  ngOnInit(): void {

    // Read the token from URL
    this.route.paramMap.subscribe(params => {
      this.key = params.get("key")
    });

    // Validate token 
    this.loginService.validateToken(this.key).subscribe(
      data => {

        for(let key in data) {
          this.validationobj.push(data[key]);
        }

        //hide loader
        this.loading = false;

        // actions according token response
        if (this.validationobj[0]) {
          console.log("Token is valid, you can proceed to login!");
        } else if (!this.validationobj[0]) {
          this.showForm = false;
          this.error = "Invalid Token, you can't login";
        }

      },
      error => {
        
        console.log("Error: " + error);
      }
    );

    // Form Validation
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    // check if token is expired
    // if token not expired
    if (this.validationobj[1] >= this.datePipeString) {
      console.log("Token not expired");

      // Check username and password
      this.loginService.loginCheck(this.f.username.value, this.f.password.value, this.key).subscribe(
        // POST call successful
        (val) => {
          // create array to store values
          for(let key in val) {
            this.loginResponse.push(val[key]);
          }
          // check response
          if (this.loginResponse[0]) {
            // Correct credentials
            this.loginService.loginFinalize(this.key);
            console.log('Login OK, finalize to: ' + this.loginFinalize[0]);

          } else if (!this.loginResponse[0]) {
            // Wrong credentials
            this.loading = false;
            console.log(this.loginResponse[1]);
            this.error = this.loginResponse[1];

          }
        },
        // POST call error
        response => {
          console.log("POST call in error", response);
        }
      )

    } // if token expired 
    else if (this.validationobj[1] < this.datePipeString) {
      this.loading = false;
      console.log("Token expired");
      this.error = "Token expired";
    }
  };

  // Switching method
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
