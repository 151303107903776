import { FormGroup } from '@angular/forms';

// custom validator to check that two fields not match
export function MustNotMatch(controlName: string, mismatchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const mismatchingControl = formGroup.controls[mismatchingControlName];

        if (mismatchingControl.errors && !mismatchingControl.errors.mustMismatch) {
            // return if another validator has already found an error on the mismatchingControl
            return;
        }

        // set error on mismatchingControl if validation fails
        if (control.value == mismatchingControl.value) {
          mismatchingControl.setErrors({ mustMismatch: true });
        } else {
          mismatchingControl.setErrors(null);
        }
    }
}