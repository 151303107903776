<!-- Loading Animation -->
<loading-animation [showLoading]="loading"></loading-animation>

<!-- Page Content -->
<div class="row justify-content-center h-100">
  <div class="col-sm-12 col-md-6 col-lg-5 align-self-center form-group">
    <!-- Logo -->
    <img src="./../../../assets/img/genio-diligence-logo-colored.png" alt="Genio Diligence" class="logo">

    <!-- Card reset password / This will be visible only after user click on reset link sent by the system via email -->
    <div class="card shadow rounded" *ngIf="resetForm">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Recupero Password</h3>
        <p class="card-text text-center">Per mantenere la sicurezza, assicurati che la tua nuova password contenga:
        </p>
        <ul>
          <li>almeno 8 caratteri</li>
          <li>massimo 16 caratteri</li>
          <li>almeno 1 lettera maiuscola</li>
          <li>almeno 1 lettera minuscola</li>
          <li>almeno 1 numero</li>
          <li>almeno 1 carattere speciale ($@$!%*?&)</li>
        </ul>

        <form [formGroup]="resetFormGroup" (ngSubmit)="onReset()">

          <!-- New Password -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="password" class="text-muted">Nuova Password:</label>
              <div class="input-group">
                <input [type]="fieldNewPaswTextType ? 'text' : 'password'" name="newpassword" id="newpassword"
                  class="form-control" formControlName="newpassword" placeholder="Scrivi la tua password qui"
                  [ngClass]="{ 'is-invalid': submitted && s.newpassword.errors }">
                <div class="input-group-append">
                  <span class="input-group-text toggle-password-btn" (click)="toggleFieldNewPasswTextType()"
                    data-toggle="tooltip" data-placement="top"
                    [title]="fieldNewPaswTextType ? 'Clicca per nascondere la password' : 'Clicca per mostrare la password'">
                    <!-- icon show password -->
                    <span *ngIf="fieldNewPaswTextType">
                      <i class="far fa-eye"></i>
                    </span>
                    <!-- icon hide password -->
                    <span *ngIf="!fieldNewPaswTextType">
                      <i class="far fa-eye-slash"></i>
                    </span>
                  </span>
                </div>
              </div>
              <!-- Error messages -->
              <div *ngIf="submitted && s.newpassword.errors" class="invalid-feedback">
                <div *ngIf="s.newpassword.errors.required">La password &egrave; obbligatoria</div>
                <div *ngIf="s.newpassword.errors.minlength">La password deve contenere almeno 8 caratteri</div>
                <div *ngIf="s.newpassword.errors.maxlength">La password deve essere al massimo di 16 caratteri</div>
                <div *ngIf="s.newpassword.errors.pattern">La password deve contenere almeno 1 minuscola, 1 maiuscola, 1
                  numero, 1 carattere speciale</div>
              </div>
            </div>
          </div>

          <!-- Confirm Password -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="password" class="text-muted">Conferma Nuova Password:</label>
              <div class="input-group">
                <input [type]="fieldConfirmPasswTextType ? 'text' : 'password'" name="confirmpassword"
                  id="confirmpassword" class="form-control" formControlName="confirmpassword"
                  placeholder="Conferma la tua password qui"
                  [ngClass]="{ 'is-invalid': submitted && s.confirmpassword.errors }">
                <div class="input-group-append">
                  <span class="input-group-text toggle-password-btn" (click)="toggleFieldConfirmPasswTextType()"
                    data-toggle="tooltip" data-placement="top"
                    [title]="fieldConfirmPasswTextType ? 'Clicca per nascondere la password' : 'Clicca per mostrare la password'">
                    <!-- icon show password -->
                    <span *ngIf="fieldConfirmPasswTextType">
                      <i class="far fa-eye"></i>
                    </span>
                    <!-- icon hide password -->
                    <span *ngIf="!fieldConfirmPasswTextType">
                      <i class="far fa-eye-slash"></i>
                    </span>
                  </span>
                </div>
              </div>
              <!-- Error messages -->
              <div *ngIf="submitted && s.confirmpassword.errors" class="invalid-feedback">
                <div *ngIf="s.confirmpassword.errors.required">La password di conferma &egrave; obbligatoria</div>
                <div *ngIf="s.confirmpassword.errors.mustMatch">La password di conferma deve essere uguale alla nuova
                  password</div>
              </div>
            </div>
          </div>

          <!-- Recover via email button -->
          <div class="row justify-content-center mt-4">
            <div class="col-12 form-group">
              <button type="submit" class="btn btn-primary btn-block">
                Cambia la password
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>

  </div>
</div>
