<!-- Loading Animation -->
<loading-animation [showLoading]="loading"></loading-animation>

<!-- Page Content -->
<div class="row justify-content-center h-100">
  <div class="col-sm-12 col-md-6 col-lg-5 align-self-center form-group">
    <!-- Logo -->
    <img src="./../../../assets/img/genio-diligence-logo-colored.png" alt="Genio Diligence" class="logo">

    <!-- Card recover password request -->
    <div class="card shadow rounded" *ngIf="!emailSentResponse">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Recupero Password</h3>
        <p class="card-text text-center mb-5">Inserisci il tuo username per avviare la procedura di recupero password.
        </p>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <!-- Username -->
          <div class="row">
            <div class="col-12 form-group">
              <label for="username" class="text-muted">Username:</label>
              <input type="text" name="username" id="username" class="form-control" formControlName="username"
                placeholder="Scrivi il tuo username qui" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
              <!-- Error messages -->
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Inserisci il tuo username</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <!-- reCaptcha - Key and settings are set in app.module -->
              <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"></re-captcha>
            </div>
          </div>

          <!-- Recover via email button -->
          <div class="row justify-content-center mt-4">
            <div class="col-12 form-group">
              <button type="submit" class="btn btn-primary btn-block" [disabled]="form.invalid">
                Inviami la mail con il link di recupero
              </button>
            </div>
          </div>

        </form>

        <!-- Links to other pages -->
        <p class="mt-5 text-center"><a href="/login" alt="Torna alla pagina di Login" data-toggle="tooltip"
            data-placement="top" title="Torna alla pagina di Login">Torna alla pagina di login</a></p>
      </div>
    </div>

    <!-- Card recover password response -->
    <div class="card shadow rounded" *ngIf="emailSentResponse">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">Recupero Password</h3>
        <p class="card-text text-center mb-3">Email inviata!</p>
        <p class="card-text text-center mb-3 fa-3x text-secondary">
          <i class="fas fa-envelope fa-spin"></i>
        </p>
        <p class="card-text text-justify mb-5">Ti abbiamo inviato una email. Clicca sul link che troverai al suo interno
          per proseguire con la procedura di recupero password.</p>
        <p class="card-text text-right"><i>Grazie!</i></p>
      </div>
    </div>

  </div>
</div>
