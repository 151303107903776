import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import custom validator to validate that password and confirm password fields match
import { MustMatch } from '@app/_helpers/must-match.validator';
// import custom validator to validate that old password and confirm new password fields match
import { MustNotMatch } from '@app/_helpers/must-not-match.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  returnUrl: string = "";
  // Show or Hide password
  fieldOldPasswType: boolean = false;
  fieldNewPasswTextType: boolean = false;
  fieldConfirmPasswTextType: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', [
        Validators.required, 
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9$@$!%*?&].{1,}'),
        Validators.minLength(8),
        Validators.maxLength(16)
      ]],
      confirmpassword: ['', Validators.required]
    }, {
      validator: [
        MustMatch('newpassword', 'confirmpassword'), 
        MustNotMatch('oldpassword', 'newpassword')
      ]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    // Set a fake loading animation then show message sent
    this.loading = true;
    setTimeout (() => {
      this.loading = false;
    }, 4000);


    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.form.value, null, 4));

  }

  // Switching field type methods
  toggleFieldOldPasswType() {
    this.fieldOldPasswType = !this.fieldOldPasswType;
  }

  toggleFieldNewPasswTextType() {
    this.fieldNewPasswTextType = !this.fieldNewPasswTextType;
  }

  toggleFieldConfirmPasswTextType() {
    this.fieldConfirmPasswTextType = !this.fieldConfirmPasswTextType;
  }

}
