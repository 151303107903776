<!-- Page Content -->
<div class="row justify-content-center h-100">
  <div class="col-sm-12 col-md-6 col-lg-5 align-self-center form-group">

    <!-- Logo -->
    <img src="./../../../assets/img/genio-diligence-logo-colored.png" alt="Genio Diligence" class="logo">

    <!-- Card -->
    <div class="card shadow rounded">
      <div class="card-body">
        <h3 class="card-title mb-4 text-primary text-center">User Profile</h3>
        <p class="card-text text-center">Ciao <i class="text-secondary">{{ username }}</i> !</p>
        <p class="card-text text-center mb-5">Di seguito troverai tutte le informazioni relative al tuo profilo utente:</p>
        <ul class="list-group list-group-flush mb-5">
          <li class="list-group-item"><i class="fas fa-user text-muted mr-3"></i> Mario Rossi</li>
          <li class="list-group-item"><i class="fas fa-building text-muted mr-3"></i> Superazienda SPA</li>
          <li class="list-group-item"><i class="fas fa-map-marker-alt text-muted mr-3"></i> Via Vicolo Stretto, 12345 Monopoli (MP)</li>
          <li class="list-group-item"><span class="text-muted"><abbr title="Partita Imposta Valore Aggiunto">P.IVA</abbr>:</span> 12312312312312311</li>
          <li class="list-group-item"><span class="text-muted"><abbr title="Codice Fiscale">C.F.</abbr>:</span> RKFDLE34E02B304J</li>
          <li class="list-group-item"><span class="text-muted">NREA:</span> LU 12312313</li>
        </ul>
        <a type="button" role="button" href="/dashboard" class="btn btn-primary btn-block">Torna alla Dashboard</a>
      </div>
    </div>

  </div>
</div>
